<template>
  <footer>
    <div class="footer-row">
      <div class="col-xs-12 col-sm-12 col-md-6 text-start">
        
          <a v-bind:href="$t('links.logo')" target="_blanc" class="logo">
            <img src="../assets/Vapaus-logo-white.svg" height="40" alt="Vapaus logo" />
          </a>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 text-end">

        <ul class="links">
          <li>
            <a v-bind:href="$t('links.tietosuoja-url')" target="_blanc">
                  {{$t('links.tietosuoja-label')}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return { 
    }
  },
  methods: {
  }
};
</script>

<style>
</style>